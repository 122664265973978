import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { Node as ProseMirrorNode } from 'prosemirror-model';

import { GoalTagType } from 'types/GoalTag';

import { GoalTagNodeView } from './components/GoalTagNodeView';

// Add goalTag commands to the Tiptap Commands interface
declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Commands<ReturnType> {
    goalTag: {
      addGoalTag: ({ type }: { type: GoalTagType }) => ReturnType;
      removeGoalTag: ({
        node,
        from,
      }: {
        node: ProseMirrorNode;
        from: number;
      }) => ReturnType;
    };
  }
}

// GoalTagExtension is a Tiptap extension that allows users to add goal tags to their text
export const GoalTagExtension = Node.create({
  name: 'goalTag',

  group: 'inline',
  inline: true,
  content: 'text*',
  atom: false,

  addAttributes: () => ({
    type: {
      default: null,
    },
  }),

  parseHTML: () => [{ tag: 'goal-tag' }],

  renderHTML: ({ HTMLAttributes }) => [
    'goal-tag',
    mergeAttributes(HTMLAttributes),
  ],

  addNodeView: () => ReactNodeViewRenderer(GoalTagNodeView),

  addCommands: () => ({
    // add a goal tag for the selected text
    addGoalTag: ({ type }: { type: GoalTagType }) => ({ chain, state }) => {
      const { selection } = state;

      // get the selected text
      const selectedText = state.doc.textBetween(selection.from, selection.to);

      // add a goal tag for the selected text
      return (
        chain()
          .setMeta('command', 'add_goal_tag')
          // replace the selected text with a goal tag incl. the selected text
          .insertContent({
            type: 'goalTag',
            content: [
              {
                type: 'text',
                text: selectedText,
              },
            ],
            attrs: {
              type,
            },
          })
          .run()
      );
    },

    // remove the goal tag
    removeGoalTag: ({
      node,
      from,
    }: {
      node: ProseMirrorNode;
      from: number;
    }) => ({ chain }) => {
      const to = from + node.nodeSize;
      const content = node.textContent;

      return (
        chain()
          .setMeta('command', 'remove_goal_tag')
          // first remove the range incl. the goal tag
          .deleteRange({ from, to })
          // then insert the content at the same position, but without the goal tag
          .insertContentAt(from, content)
          .run()
      );
    },
  }),
});
