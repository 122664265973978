/* eslint-disable no-underscore-dangle */

// TODO: use types for the `type` arg for formatValue and formatValueHuman like `numeric` | `percental` | `percentalFloat` | `monetary`

import { Currency } from 'types/Currency';

export type Locale = 'en' | 'de';

const _getOptionsForNumeric = (): Intl.NumberFormatOptions => ({
  maximumFractionDigits: 2,
});

const _getHumanOptionsForNumeric = (): Intl.NumberFormatOptions => ({
  notation: 'compact',
  maximumSignificantDigits: 3,
});

const _getOptionsForPercent = (): Intl.NumberFormatOptions => ({
  style: 'percent',
  maximumFractionDigits: 2,
});

const _getHumanOptionsForPercent = (): Intl.NumberFormatOptions => ({
  notation: 'compact',
  style: 'percent',
  maximumSignificantDigits: 3,
});

const _getOptionsForCurrency = (
  currency: Currency
): Intl.NumberFormatOptions => ({
  style: 'currency',
  currency,
  currencyDisplay: 'narrowSymbol',
});

const _getHumanOptionsForCurrency = (
  currency: Currency
): Intl.NumberFormatOptions => ({
  notation: 'compact',
  style: 'currency',
  currency,
  currencyDisplay: 'narrowSymbol',
  maximumSignificantDigits: 3,
});

const formatValue = (
  locale: Locale,
  type: string,
  value: number,
  currency: Currency = 'EUR'
): string => {
  let options: Intl.NumberFormatOptions = {};

  if (type === 'numeric') {
    options = _getOptionsForNumeric();
  }

  if (type === 'percental' || type === 'percentalFloat') {
    options = _getOptionsForPercent();
  }

  if (type === 'monetary') {
    options = _getOptionsForCurrency(currency);
  }

  return new Intl.NumberFormat(locale, options).format(value);
};

const formatValueHuman = (
  locale: Locale,
  type: string,
  value: number,
  currency: Currency = 'EUR'
): string => {
  let options: Intl.NumberFormatOptions = {};

  if (type === 'numeric') {
    options = _getHumanOptionsForNumeric();
  }

  if (type === 'percental' || type === 'percentalFloat') {
    options = _getHumanOptionsForPercent();
  }

  if (type === 'monetary') {
    options = _getHumanOptionsForCurrency(currency);
  }

  // This is a workaround for the fact that the currency formatter in de locale
  // does not round to the nearest integer when the value is a multiple of 1000.
  if (locale === 'de') {
    // For percental, show 1.000% - 999.999% as "1 Tsd. %" - "999 Tsd. %"
    // For all other types, show 1.000 - 999.999 as "1 Tsd." - "999 Tsd."
    const min = type === 'percental' || type === 'percentalFloat' ? 1e1 : 1e3; // 1.000% = 1e1; 1.000 = 1e3
    const max = type === 'percental' || type === 'percentalFloat' ? 1e4 : 1e6; // 1.000.000% = 1e4; 1.000.000 = 1e6

    if (min <= Math.abs(value) && Math.abs(value) < max) {
      // Formatted value like "123", "37,3 %", or "123,45 €"
      const formattedValue = new Intl.NumberFormat(locale, options).format(
        value / 1e3
      );
      const [number, unit] = formattedValue.split(/\s+/); // Split by any whitespace including regular space and non-breaking space

      return `${number}\u00A0Tsd.${unit ? `\u00A0${unit}` : ''}`;
    }
  }

  return new Intl.NumberFormat(locale, options).format(value);
};

export { formatValue, formatValueHuman };
