import { getRequest, patchRequest } from '../../methods';

const getUser = userId => getRequest(`users/${userId}`);
const editUser = (userId, payload) => patchRequest(`users/${userId}`, payload);
const getUserTeams = userId => getRequest(`users/${userId}/teams`);
const getUserTeamsCreatable = () => getRequest('users/me/teams/goal_creatable');

const getKeyResultStatusUpdates = () =>
  getRequest('users/me/key_result_status_updates');

const getCurrentUserCheckIns = filter =>
  getRequest(`users/me/report_intervals/${filter}`);
const getCurrentUserTeamMemberReport = checkInId =>
  getRequest(`users/me/report_intervals/${checkInId}/team_member_report`);
const getCurrentUserCheckInAnswers = checkInId =>
  getRequest(`users/me/report_intervals/${checkInId}/previous_with_answers`);

const getUserCoachedTeams = userId =>
  getRequest(`users/${userId}/teams/coached`);

const getCurrentUserTeams = () => getRequest(`users/me/teams`);
const getCurrentUserCoachedTeams = () => getRequest(`users/me/teams/coached`);

const users = {
  getUserTeams,
  getUser,
  editUser,
  getUserTeamsCreatable,

  getKeyResultStatusUpdates,

  getCurrentUserCheckIns,
  getCurrentUserCheckInAnswers,
  getCurrentUserTeamMemberReport,
  getUserCoachedTeams,
  getCurrentUserTeams,
  getCurrentUserCoachedTeams,
};

export default users;
