import React from 'react';

import { Locale } from 'types/Locale';
import { Team } from 'types/Team';

import { StrategicElement } from 'types/gql/graphql';
import { Icons } from '../../Icons';
import { Size } from '../index';

import { SharedListItemLayout } from './components/SharedListItemLayout';

type StrategicElementListItemProps = {
  // TODO: Add owners when ready
  // @ts-ignore
  strategicElement: Pick<StrategicElement, 'title' | 'owners'> & {
    id: number;
  };
  size: Size;
  showEntitySymbol: boolean;
  showOwnerAvatar: boolean;
  locale: Locale;
  padding: number;
  onClick?: () => void;
  variant?: 'prependOwner' | 'appendOwner';
  showActionButton?: boolean;
  isClickable?: boolean;
};

const StrategicElementListItem = ({
  strategicElement,
  size,
  showEntitySymbol,
  showOwnerAvatar,
  padding,
  isClickable = false,
  onClick,
  variant = 'prependOwner',
  showActionButton = false,
}: StrategicElementListItemProps) => {
  const handleUnlink = () => {
    // TODO: Hook up to API when ready
  };

  const unlinkProps = {
    showActionButton: true,
    onClick,
  };

  return (
    <SharedListItemLayout
      entityType="strategicElement"
      showEntitySymbol={showEntitySymbol}
      showOwnerAvatar={showOwnerAvatar}
      ownerKind={strategicElement?.owners?.[0].ownerType}
      owner={strategicElement?.owners?.[0].owner as Team}
      padding={padding}
      size={size}
      title={strategicElement.title}
      variant={variant}
      onClick={onClick}
      actionButtonIcon={Icons.LinkOff}
      onActionButtonClick={handleUnlink}
      showActionButton={showActionButton}
      isClickable={isClickable}
    />
  );
};

export { StrategicElementListItem };
