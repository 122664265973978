import {
  getRequestV2,
  postRequestV2,
  patchRequestV2,
  deleteRequestV2,
} from '../methods';

type StrategicElementData = {
  title: string;
  description: string;
  kind: 'team' | 'organization';
  owners: { ownerType: string; ownerId: number }[];
};

const createStrategicElement = (payload: {
  strategicElement: StrategicElementData;
}) => postRequestV2('strategic_elements', payload);

const getStrategicElements = (ownerType: string, ownerId?: string) =>
  getRequestV2(
    `strategic_elements?owner_type=${ownerType}&owner_id=${ownerId}`
  );

const getStrategicElement = (strategicElementId: number) =>
  getRequestV2(`strategic_elements/${strategicElementId}`);

const editStrategicElement = (
  strategicElementId: number,
  payload: {
    strategicElement: StrategicElementData;
  }
) => patchRequestV2(`strategic_elements/${strategicElementId}`, payload);

const deleteStrategicElement = (strategicElementId: number) =>
  deleteRequestV2(`strategic_elements/${strategicElementId}`);

const strategicElements = {
  createStrategicElement,
  getStrategicElements,
  getStrategicElement,
  editStrategicElement,
  deleteStrategicElement,
};

export default strategicElements;
