import { defineMessages } from 'react-intl';

export default defineMessages({
  tagSelectionAs: {
    id: 'baukasten.GoalTagExtension.tagSelectionAs',
    defaultMessage: 'Tag selection as',
  },
  customer: {
    id: 'baukasten.GoalTagExtension.customer',
    defaultMessage: 'Customer',
  },
  customerTooltip: {
    id: 'baukasten.GoalTagExtension.customerTooltip',
    defaultMessage:
      'Mention customer or internal customer who will benefit from your efforts.',
  },
  value: {
    id: 'baukasten.GoalTagExtension.value',
    defaultMessage: 'Value',
  },
  valueTooltip: {
    id: 'baukasten.GoalTagExtension.valueTooltip',
    defaultMessage: 'Define value that you will create for them.',
  },
  futureState: {
    id: 'baukasten.GoalTagExtension.futureState',
    defaultMessage: 'Future State',
  },
  futureStateTooltip: {
    id: 'baukasten.GoalTagExtension.futureStateTooltip',
    defaultMessage:
      'Define future state that you will achieve by the end of the cycle.',
  },
});
