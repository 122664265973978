import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  GetInputPropsOptions,
  GetPropsCommonOptions,
  GetToggleButtonPropsOptions,
  useCombobox,
  UseComboboxGetComboboxPropsOptions,
} from 'downshift';

import { InputField } from '../components/InputField';
import { ToggleButton } from '../components/ToggleButton';
import { InputFieldClearButton } from '../components/InputFieldClearButton';

import { Box } from '../../Box';
import { Item, Entity } from './types';
import { MultiselectFieldBody } from '../Multiselect/components/MultiselectFieldBody';
import { SelectMenu } from './components/SelectMenu';
import { SelectedItem } from './renderers/SelectedItemRenderer';

const Boxed = styled(Box)`
  flex: 1;
  flex-flow: wrap;
`;

const SelectMenuWrapper = styled.span`
  position: relative;
`;

const searchFilter = (inputValue: string) => ({ title }): Entity[] => {
  const lowerCasedInputValue = inputValue.toLowerCase();

  return !inputValue || title.toLowerCase().includes(lowerCasedInputValue);
};

type SelectSearchBodyProps = {
  getComboboxProps: (
    options?: UseComboboxGetComboboxPropsOptions,
    otherOptions?: GetPropsCommonOptions
  ) => unknown;
  getInputProps: <T>(options?: T) => T & GetInputPropsOptions;
  openMenu: (cb?: () => void) => void;
  placeholder: string;
  isOpen: boolean;
  disabled: boolean;
  selectedItem: Entity;
  hideClearButton: boolean;
  selectItem: (item: Item) => void;
  getToggleButtonProps: (options?: GetToggleButtonPropsOptions) => unknown;
  name: string;
};
const SelectSearchBody = ({
  getComboboxProps,
  getInputProps,
  openMenu,
  placeholder,
  isOpen,
  disabled,
  selectedItem,
  hideClearButton,
  selectItem,
  getToggleButtonProps,
  name,
}: SelectSearchBodyProps) => (
  <Box display="flex" justifyContent="space-between" width="100%">
    <Boxed display="flex">
      {selectedItem && (
        <SelectedItem
          item={selectedItem as Item}
          isOpen={isOpen}
          placeholder={placeholder}
          openMenu={openMenu}
          disabled={disabled}
        />
      )}

      <MultiselectFieldBody
        getComboboxProps={getComboboxProps}
        getInputProps={getInputProps}
        openMenu={openMenu}
        getDropdownProps={() => null}
        placeholder={placeholder}
        isOpen={isOpen}
        disabled={disabled}
      />
    </Boxed>

    <Box display="flex" justify-content="center">
      {selectedItem && !hideClearButton ? (
        <InputFieldClearButton
          onClick={() => {
            selectItem(null);
          }}
          disabled={disabled}
          align="center"
          tooltipContent="Clear"
        />
      ) : null}

      <ToggleButton
        getToggleButtonProps={getToggleButtonProps}
        isOpen={isOpen}
        disabled={disabled}
        align="center"
        name={name}
      />
    </Box>
  </Box>
);

type RawSelectSearchProps = {
  placeholder?: string;
  hasError?: boolean;
  disabled?: boolean;
  onChange: (value: Item['value'] | null) => void;
  items: Entity[];
  initialValue: Item['value'];
  name?: string;
  maxHeight?: string;
  maxTextWidth?: string;
  hideClearButton?: boolean;
  value?: Item['value'];
  onInputChange?: (value: Item['value'] | null) => void;
};

function RawSelectSearch({
  placeholder,
  hasError,
  onChange,
  disabled,
  initialValue,
  name,
  maxHeight,
  hideClearButton,
  items,
  onInputChange,
}: RawSelectSearchProps) {
  const anchorEl = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const filteredItems = items.filter(searchFilter(inputValue));

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    openMenu,
    selectItem,
    selectedItem,
    getInputProps,
    getComboboxProps,
  } = useCombobox({
    items: filteredItems,
    inputValue,
    onInputValueChange({ inputValue }) {
      setInputValue(inputValue);

      if (onInputChange) onInputChange(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem ? selectedItem.value : null);
    },
    itemToString(item) {
      return item ? item.title : '';
    },
    initialSelectedItem: items.find(item => item.value === initialValue),
  });

  return (
    <>
      <InputField
        hasError={hasError}
        disabled={disabled}
        ref={anchorEl}
        paddingRight="6px"
        isFocused={isOpen}
      >
        <SelectSearchBody
          getComboboxProps={getComboboxProps}
          getInputProps={getInputProps}
          openMenu={openMenu}
          placeholder={placeholder}
          isOpen={isOpen}
          disabled={disabled}
          selectedItem={selectedItem}
          hideClearButton={hideClearButton}
          selectItem={selectItem}
          getToggleButtonProps={getToggleButtonProps}
          name={name}
        />
      </InputField>

      <SelectMenuWrapper {...getMenuProps()}>
        <SelectMenu
          isOpen={isOpen}
          anchorEl={anchorEl}
          initialValue={initialValue}
          maxHeight={maxHeight}
          items={filteredItems}
          getItemProps={getItemProps}
        />
      </SelectMenuWrapper>
    </>
  );
}

export { RawSelectSearch };
