import React, { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Team } from 'types/Team';

import { Avatar } from '../../../Avatar';
import { Box } from '../../../Box';
import { EntitySymbol } from '../../../EntitySymbol';
import { FontSizeKeys } from '../../../styles/themes';
import { getBaseUnit, getColor } from '../../../styles/themeGetters';
import { TeamAvatar } from '../../../TeamAvatar';
import { Text } from '../../../Text';
import { Truncate } from '../../../Truncate';

import messages from '../../messages';
import { Size } from '../../index';
import { IconComponent } from '../../../Icons/helpers';

type SizeProp = {
  fontSize: FontSizeKeys;
  // EntitySymbol has slightly different size props than the Avatar component, hence the size conversion
  entitySymbolSize: 's' | 'm';
};

type SizeProps = {
  xs: SizeProp;
  s: SizeProp;
  m: SizeProp;
};

type StyledClickableBox = {
  isClickable: boolean;
};

// Assuming future differentiation, keeping SIZES s and m separate
export const SIZES: SizeProps = {
  xs: {
    fontSize: 14,
    entitySymbolSize: 's',
  },
  s: {
    fontSize: 16,
    entitySymbolSize: 'm',
  },
  m: {
    fontSize: 16,
    entitySymbolSize: 'm',
  },
};

const Wrapper = styled(Box)<StyledClickableBox>`
  &:hover {
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
    background-color: ${({ isClickable }) =>
      isClickable ? getColor('grey-light-50') : ''};
  }
`;

const ActionWrapper = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

type SharedListItemLayoutProps = {
  // Entity
  entityType: 'kpi' | 'strategicElement'; // or other entity types in the future

  // Entity Symbol
  showEntitySymbol?: boolean;

  // Entity Owner
  showOwnerAvatar?: boolean;
  ownerKind?: 'organization' | 'team';
  owner?: Team;

  // Title & Details
  title?: string;
  details?: string;

  // Misc
  padding?: number;
  size: Size;
  variant?: 'prependOwner' | 'appendOwner';
  isClickable?: boolean;
  onClick?: () => void;
  showActionButton?: boolean;
  actionButtonIcon?: IconComponent;
  onActionButtonClick?: () => void;
};

const SharedListItemLayout = ({
  entityType,
  showEntitySymbol,
  showOwnerAvatar,
  ownerKind,
  owner,
  title,
  details,
  padding,
  size,
  isClickable = true,
  onClick,
  variant = 'prependOwner',
  showActionButton,
  actionButtonIcon: ActionButtonIcon,
  onActionButtonClick,
}: SharedListItemLayoutProps) => {
  const { formatMessage } = useIntl();

  // Owner Avatar
  let ownerAvatar: React.ReactNode;

  switch (ownerKind) {
    case 'organization':
      ownerAvatar = (
        <Avatar
          type="organization"
          size={size}
          fallbackText={formatMessage(messages.organizationFallbackText)}
        />
      );
      break;
    case 'team':
      ownerAvatar = <TeamAvatar team={owner} size={size} />;
      break;
  }

  const handleActionButtonClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onActionButtonClick();
  };

  const clickableProps = isClickable ? { onClick } : {};

  return (
    <Wrapper
      display="flex"
      alignItems="center"
      pl={padding}
      pr={padding}
      pt={padding}
      pb={padding}
      round
      flexGap={getBaseUnit(2)}
      flex="1"
      aria-label={`${entityType} list item`}
      isClickable={isClickable}
      {...clickableProps}
    >
      <Box display="flex" flex="1" flexGap={getBaseUnit(2)} alignItems="center">
        {showEntitySymbol && (
          <EntitySymbol
            size={SIZES[size].entitySymbolSize}
            variant={entityType}
          />
        )}

        {showOwnerAvatar && variant === 'prependOwner' && ownerAvatar}

        <Box display="flex" alignItems="center">
          <Truncate
            maxLines={2}
            text={title}
            textProps={{ fontSize: SIZES[size].fontSize }}
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Text minWidth="60px" fontSize={SIZES[size].fontSize}>
          {details}
        </Text>
      </Box>

      {showOwnerAvatar && variant === 'appendOwner' && ownerAvatar}

      {showActionButton && (
        <Box display="flex" alignItems="stretch">
          <Box
            width="2px"
            backgroundColor="grey-light-100"
            flex="1"
            mr={2}
            mt={1}
            mb={1}
            ml={2}
          />
          <ActionWrapper onClick={handleActionButtonClick}>
            <ActionButtonIcon />
          </ActionWrapper>
        </Box>
      )}
    </Wrapper>
  );
};

export { SharedListItemLayout };
