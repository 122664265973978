import * as React from 'react';
import { Ref, forwardRef } from 'react';

const SvgStrategicElement = (_, ref: Ref<SVGSVGElement>) => (
  <svg
    width={_.width}
    height={_.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
  >
    <path
      d="m13.38 12.072 1.325-.814 3.47 2.085c.288.173.498.341.629.505.13.164.196.358.196.582 0 .22-.065.412-.196.576-.13.164-.34.332-.63.505l-5.254 3.173c-.176.103-.338.18-.487.232a1.186 1.186 0 0 1-.873 0c-.14-.052-.3-.129-.48-.232l-5.255-3.173c-.288-.173-.498-.341-.629-.505A.895.895 0 0 1 5 14.431c0-.225.065-.42.196-.583.13-.164.34-.332.63-.506l3.61-2.168 1.34.807-4.031 2.38c-.027.018-.04.041-.04.07 0 .028.013.051.04.07l4.978 2.934c.103.06.196.091.277.091.077 0 .17-.03.277-.091l4.971-2.934c.032-.019.048-.042.048-.07 0-.029-.016-.052-.048-.07l-3.868-2.289ZM12 13.146c-.149 0-.295-.026-.44-.077-.14-.056-.3-.138-.48-.246L5.825 9.664c-.288-.177-.498-.348-.629-.512A.905.905 0 0 1 5 8.57c0-.22.065-.411.196-.575.13-.164.34-.332.63-.505l5.254-3.166c.18-.108.34-.187.48-.239a1.185 1.185 0 0 1 .872 0c.15.052.312.131.488.239l5.255 3.166c.288.173.498.341.629.505.13.164.196.356.196.575a.906.906 0 0 1-.196.583c-.13.164-.34.335-.63.512l-5.254 3.16c-.176.107-.338.189-.487.245a1.28 1.28 0 0 1-.433.077Z"
      fill={_.color}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStrategicElement);
export default ForwardRef;
