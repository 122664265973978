import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { Text } from '../../../Text';
import { getRelativeDateUnits } from '../../../utils/date';

import { RendererProps } from '../RendererProps';

import { Box } from '../../../Box';
import { Skeleton } from '../../../Skeleton';
import { Tooltip } from '../../../Tooltip';
import { HoverBox } from '../../common/HoverBox';
import { getBaseUnit } from '../../../styles/themeGetters';
import messages from '../../common/messages';
import { TD } from '../../../Table';
import { IconBox, Icons } from '../../../Icons';

const IconWrapper = styled(IconBox)`
  margin-right: ${getBaseUnit(2)};
`;

export const LastUpdateRenderer = (props: RendererProps<'lastUpdated'>) => {
  const { locale, formatMessage } = useIntl();

  if (
    props.loadingState === 'loading' ||
    // @ts-ignore PL-2222
    props.cell.entityType === 'last_updated_loading_cell'
  ) {
    return (
      // min: 102 max: 152
      // 152 - 16 = 136
      <TD width="136" pl="16">
        <Skeleton />
      </TD>
    );
  }
  const { value, permissions, entityType } = props.cell;
  const displayWarning = props.cell.showWarning;

  switch (entityType) {
    case 'goal_last_updated':
    case 'key_result_last_updated':
    case 'initiative_last_updated':
      if (value) {
        const humanisedDate: string = new Intl.RelativeTimeFormat(locale, {
          numeric: 'auto',
          style: 'narrow',
        }).format(
          getRelativeDateUnits(value).value,
          getRelativeDateUnits(value).unit
        );
        const applyHoverEffect =
          props.cell.entityType === 'initiative_last_updated' ||
          props.cell.entityType === 'key_result_last_updated';
        const isClickable = permissions.canEdit && applyHoverEffect;

        return (
          <TD minWidth="136">
            <Box
              height="56px"
              display="flex"
              alignItems="center"
              aria-label="cell last updated"
            >
              <Tooltip
                show={isClickable ? undefined : false}
                tooltipContent={formatMessage(messages.clickToUpdate)}
              >
                <HoverBox
                  applyHoverEffect={isClickable}
                  display="flex"
                  alignItems="center"
                  cursor={isClickable ? 'pointer' : undefined}
                  onClick={() =>
                    isClickable &&
                    props.config.onClickCell(props.cell, props.address)
                  }
                  aria-label="update"
                >
                  {displayWarning && (
                    <IconWrapper>
                      <Icons.InProgress color="red" size="16px" noPadding />
                    </IconWrapper>
                  )}
                  <Box display="flex" flexGap="4px" alignItems="center">
                    <Text
                      color={props.hasRecentlyUpdated ? 'black' : 'grey-dark'}
                      fontWeight={props.hasRecentlyUpdated ? 'bold' : 'regular'}
                      fontSize={14}
                    >
                      {humanisedDate}
                    </Text>
                  </Box>
                </HoverBox>
              </Tooltip>
            </Box>
          </TD>
        );
      }
      return null;

    case 'draft_goal_last_updated':
    case 'draft_key_result_last_updated':
    case 'draft_initiative_last_updated':
      return (
        <TD width="136" pl="16">
          <Text fontSize={14} color="grey-dark" ml={4}>
            -
          </Text>
        </TD>
      );
  }
};
