import React, { Children, ElementType, ReactNode } from 'react';
import { Icons } from '../Icons';
import { Tooltip } from '../Tooltip';
import {
  StyledBreadcrumbWrapper,
  StyledBreadcrumbItem,
  StyledBreadcrumbText,
  StyledDropdownPositioner,
  StyledDropdownItem,
  StyledIconCaret,
  StyledDropdownWrapper,
  StyledDropdown,
} from './styles';

type BreadcrumbProps = {
  children: ReactNode;
  as?: string | ElementType;
};

type BreadcrumbItemProps = {
  children: ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  onClick?: () => void;
  tooltipContent?: string;
  'aria-label'?: string;
};

type DropdownProps = {
  children: ReactNode;
};

type DropdownItemProps = {
  children: ReactNode;
  isLast?: boolean;
  onClick?: () => void;
};

type BreadcrumbsRendererProps = {
  breadcrumbs: Array<{
    id: number;
    name: string;
  }>;
  navigateTo: (id: number) => void;
  teamName: string;
  minDropdownItems?: number;
  as?: string | ElementType;
  ancestorTeamAriaLabel?: string;
};

type BreadcrumbComponent = {
  (props: BreadcrumbProps): JSX.Element;
  Item: (props: BreadcrumbItemProps) => JSX.Element;
  Dropdown: (props: DropdownProps) => JSX.Element;
  DropdownItem: (props: DropdownItemProps) => JSX.Element;
  BreadcrumbsRenderer: (props: BreadcrumbsRendererProps) => JSX.Element;
};

const Breadcrumb: BreadcrumbComponent = ({ children, as }) => {
  const childrenWithoutNull = Children.toArray(children).filter(Boolean);
  return (
    <StyledBreadcrumbWrapper as={as}>
      {childrenWithoutNull.map((child, index) =>
        React.cloneElement(child as React.ReactElement, {
          isLast: childrenWithoutNull.length === index + 1,
          isFirst: index === 0,
        })
      )}
    </StyledBreadcrumbWrapper>
  );
};

const BreadcrumbItem = ({
  children,
  isFirst,
  isLast,
  onClick,
  tooltipContent,
  ...props
}: BreadcrumbItemProps) => {
  const styledBreadcrumbItem = (
    <StyledBreadcrumbItem isLast={isLast} {...props}>
      <StyledBreadcrumbText
        onClick={onClick}
        color={isLast ? 'navy' : 'grey-dark'}
        mb={0}
        fontSize={14}
        truncated
        maxWidth={isFirst || isLast ? '20ch' : '15ch'}
      >
        {children}
      </StyledBreadcrumbText>
      {!isLast && <Icons.ChevronRight color="grey-dark" />}
    </StyledBreadcrumbItem>
  );

  return tooltipContent ? (
    <Tooltip tooltipContent={tooltipContent}>{styledBreadcrumbItem}</Tooltip>
  ) : (
    styledBreadcrumbItem
  );
};

const Dropdown = ({ children }: DropdownProps) => (
  <StyledBreadcrumbItem>
    <StyledBreadcrumbText color="grey-dark" mb={0} fontSize={14}>
      …
    </StyledBreadcrumbText>
    <StyledDropdownWrapper>
      <StyledIconCaret>
        <Icons.ChevronDown color="grey-dark" />
      </StyledIconCaret>
      <StyledDropdownPositioner>
        <StyledDropdown>{children}</StyledDropdown>
      </StyledDropdownPositioner>
    </StyledDropdownWrapper>
    <Icons.ChevronRight color="grey-dark" />
  </StyledBreadcrumbItem>
);

const DropdownItem = ({ children, onClick, ...props }: DropdownItemProps) => (
  <StyledDropdownItem {...props}>
    <StyledBreadcrumbText
      onClick={onClick}
      color="grey-dark"
      mb={0}
      fontSize={14}
    >
      {children}
    </StyledBreadcrumbText>
  </StyledDropdownItem>
);

const BreadcrumbsRenderer = ({
  breadcrumbs,
  navigateTo,
  teamName,
  minDropdownItems = 2,
  as,
  ancestorTeamAriaLabel,
}: BreadcrumbsRendererProps) => {
  const dropdownBreadcrumbs = breadcrumbs.slice(1, -1);
  const firstBreadcrumb = breadcrumbs[0];
  const lastBreadcrumb =
    breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 1] : null;

  return (
    <Breadcrumb as={as}>
      <BreadcrumbItem
        onClick={() => navigateTo(firstBreadcrumb.id)}
        aria-label={ancestorTeamAriaLabel}
        tooltipContent={firstBreadcrumb.name}
      >
        {firstBreadcrumb.name}
      </BreadcrumbItem>

      {dropdownBreadcrumbs.length > minDropdownItems - 1 ? (
        <Dropdown>
          {dropdownBreadcrumbs.map(item => (
            <DropdownItem key={item.id} onClick={() => navigateTo(item.id)}>
              {item.name}
            </DropdownItem>
          ))}
        </Dropdown>
      ) : (
        dropdownBreadcrumbs.map(item => (
          <BreadcrumbItem
            key={item.id}
            onClick={() => navigateTo(item.id)}
            aria-label={ancestorTeamAriaLabel}
            tooltipContent={item.name}
          >
            {item.name}
          </BreadcrumbItem>
        ))
      )}

      {lastBreadcrumb && (
        <BreadcrumbItem
          onClick={() => navigateTo(lastBreadcrumb.id)}
          aria-label={ancestorTeamAriaLabel}
          tooltipContent={lastBreadcrumb.name}
        >
          {lastBreadcrumb.name}
        </BreadcrumbItem>
      )}

      <BreadcrumbItem tooltipContent={teamName} isLast>
        {teamName}
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

Breadcrumb.Item = BreadcrumbItem;
Breadcrumb.Dropdown = Dropdown;
Breadcrumb.DropdownItem = DropdownItem;
Breadcrumb.BreadcrumbsRenderer = BreadcrumbsRenderer;

export { Breadcrumb };
