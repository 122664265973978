import React from 'react';
import { FieldError } from 'react-hook-form';

import { InputControl } from '../components/InputControl';
import { RawSelectSearch } from './RawSelectSearch';
import { Items, Item } from './types';

type SelectSearchProps = {
  placeholder?: string;
  label?: string;
  helpText?: string;
  error?: FieldError;
  disabled?: boolean;
  onChange: (value: Item['value'] | null) => void;
  items: Items;
  initialValue: Item['value'];
  name?: string;
  maxHeight?: string;
  maxTextWidth?: string;
  hideClearButton?: boolean;
  value?: Item['value'];
  condensed?: boolean;
  onInputChange?: (value: Item['value'] | null) => void;
};

const SelectSearch = ({
  placeholder,
  label,
  helpText,
  error,
  onChange,
  disabled,
  items,
  initialValue,
  name,
  maxHeight,
  maxTextWidth,
  hideClearButton = false,
  value,
  condensed,
  onInputChange,
}: SelectSearchProps) => (
  <InputControl
    condensed={condensed}
    error={error}
    helpText={helpText}
    label={label}
  >
    <RawSelectSearch
      placeholder={placeholder}
      hasError={Boolean(error)}
      onChange={onChange}
      disabled={disabled}
      items={items}
      initialValue={initialValue}
      name={name}
      maxHeight={maxHeight}
      maxTextWidth={maxTextWidth}
      hideClearButton={hideClearButton}
      value={value}
      onInputChange={onInputChange}
    />
  </InputControl>
);

export { SelectSearch };
