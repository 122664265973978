/**
 * This code is generated with svgr, as well as all the other code in this directory.
 * Do not change manually, it will be overridden on the next Icon import.
 */

import { default as RawAlert } from './Alert';
import { default as RawAlignedGoal } from './AlignedGoal';
import { default as RawArchived } from './Archived';
import { default as RawArrowDown } from './ArrowDown';
import { default as RawArrowLeft } from './ArrowLeft';
import { default as RawArrowRight } from './ArrowRight';
import { default as RawArrowUp } from './ArrowUp';
import { default as RawAssist } from './Assist';
import { default as RawBlocker } from './Blocker';
import { default as RawBusinessUnit } from './BusinessUnit';
import { default as RawCalendar } from './Calendar';
import { default as RawCapacity } from './Capacity';
import { default as RawCheck } from './Check';
import { default as RawChevronDown } from './ChevronDown';
import { default as RawChevronRight } from './ChevronRight';
import { default as RawClose } from './Close';
import { default as RawCollapseAll } from './CollapseAll';
import { default as RawComment } from './Comment';
import { default as RawDanger } from './Danger';
import { default as RawDeEscalate } from './DeEscalate';
import { default as RawDecision } from './Decision';
import { default as RawDelete } from './Delete';
import { default as RawDone } from './Done';
import { default as RawDragIndicator } from './DragIndicator';
import { default as RawEdit } from './Edit';
import { default as RawEscalate } from './Escalate';
import { default as RawExecutePhase } from './ExecutePhase';
import { default as RawExpandAll } from './ExpandAll';
import { default as RawExport } from './Export';
import { default as RawExternalLink } from './ExternalLink';
import { default as RawFailed } from './Failed';
import { default as RawFeedback } from './Feedback';
import { default as RawFormatBold } from './FormatBold';
import { default as RawFormatItalic } from './FormatItalic';
import { default as RawFormatUnderline } from './FormatUnderline';
import { default as RawFunnel } from './Funnel';
import { default as RawGoal } from './Goal';
import { default as RawHelpOutline } from './HelpOutline';
import { default as RawHelpSolid } from './HelpSolid';
import { default as RawInProgress } from './InProgress';
import { default as RawInfoOutline } from './InfoOutline';
import { default as RawInfoSolid } from './InfoSolid';
import { default as RawInitiative } from './Initiative';
import { default as RawInitiativeJira } from './InitiativeJira';
import { default as RawKeyResult } from './KeyResult';
import { default as RawKudos } from './Kudos';
import { default as RawLearning } from './Learning';
import { default as RawLightning } from './Lightning';
import { default as RawLink } from './Link';
import { default as RawLinkOff } from './LinkOff';
import { default as RawLockClosed } from './LockClosed';
import { default as RawLogin } from './Login';
import { default as RawLogout } from './Logout';
import { default as RawLongTermGoal } from './LongTermGoal';
import { default as RawNotes } from './Notes';
import { default as RawNotify } from './Notify';
import { default as RawOpenSidebar } from './OpenSidebar';
import { default as RawOrganization } from './Organization';
import { default as RawOverflow } from './Overflow';
import { default as RawPending } from './Pending';
import { default as RawPlus } from './Plus';
import { default as RawProcessing } from './Processing';
import { default as RawReportIssueOutline } from './ReportIssueOutline';
import { default as RawReportIssueSolid } from './ReportIssueSolid';
import { default as RawSearch } from './Search';
import { default as RawSend } from './Send';
import { default as RawSendMe } from './SendMe';
import { default as RawSettings } from './Settings';
import { default as RawSolidChevronDown } from './SolidChevronDown';
import { default as RawSolidChevronLeft } from './SolidChevronLeft';
import { default as RawSolidChevronRight } from './SolidChevronRight';
import { default as RawSolidChevronUp } from './SolidChevronUp';
import { default as RawSort } from './Sort';
import { default as RawStar } from './Star';
import { default as RawStrategicElement } from './StrategicElement';
import { default as RawTalkingPoint } from './TalkingPoint';
import { default as RawText } from './Text';
import { default as RawUpdates } from './Updates';
import { default as RawUser } from './User';
import { default as RawValue } from './Value';
import { default as RawVideo } from './Video';
import { default as RawViewGraph } from './ViewGraph';
import { default as RawWand } from './Wand';

import { withIconProps } from '../helpers';

export const Alert = withIconProps('Alert', RawAlert);
export const AlignedGoal = withIconProps('AlignedGoal', RawAlignedGoal);
export const Archived = withIconProps('Archived', RawArchived);
export const ArrowDown = withIconProps('ArrowDown', RawArrowDown);
export const ArrowLeft = withIconProps('ArrowLeft', RawArrowLeft);
export const ArrowRight = withIconProps('ArrowRight', RawArrowRight);
export const ArrowUp = withIconProps('ArrowUp', RawArrowUp);
export const Assist = withIconProps('Assist', RawAssist);
export const Blocker = withIconProps('Blocker', RawBlocker);
export const BusinessUnit = withIconProps('BusinessUnit', RawBusinessUnit);
export const Calendar = withIconProps('Calendar', RawCalendar);
export const Capacity = withIconProps('Capacity', RawCapacity);
export const Check = withIconProps('Check', RawCheck);
export const ChevronDown = withIconProps('ChevronDown', RawChevronDown);
export const ChevronRight = withIconProps('ChevronRight', RawChevronRight);
export const Close = withIconProps('Close', RawClose);
export const CollapseAll = withIconProps('CollapseAll', RawCollapseAll);
export const Comment = withIconProps('Comment', RawComment);
export const Danger = withIconProps('Danger', RawDanger);
export const DeEscalate = withIconProps('DeEscalate', RawDeEscalate);
export const Decision = withIconProps('Decision', RawDecision);
export const Delete = withIconProps('Delete', RawDelete);
export const Done = withIconProps('Done', RawDone);
export const DragIndicator = withIconProps('DragIndicator', RawDragIndicator);
export const Edit = withIconProps('Edit', RawEdit);
export const Escalate = withIconProps('Escalate', RawEscalate);
export const ExecutePhase = withIconProps('ExecutePhase', RawExecutePhase);
export const ExpandAll = withIconProps('ExpandAll', RawExpandAll);
export const Export = withIconProps('Export', RawExport);
export const ExternalLink = withIconProps('ExternalLink', RawExternalLink);
export const Failed = withIconProps('Failed', RawFailed);
export const Feedback = withIconProps('Feedback', RawFeedback);
export const FormatBold = withIconProps('FormatBold', RawFormatBold);
export const FormatItalic = withIconProps('FormatItalic', RawFormatItalic);
export const FormatUnderline = withIconProps(
  'FormatUnderline',
  RawFormatUnderline
);
export const Funnel = withIconProps('Funnel', RawFunnel);
export const Goal = withIconProps('Goal', RawGoal);
export const HelpOutline = withIconProps('HelpOutline', RawHelpOutline);
export const HelpSolid = withIconProps('HelpSolid', RawHelpSolid);
export const InProgress = withIconProps('InProgress', RawInProgress);
export const InfoOutline = withIconProps('InfoOutline', RawInfoOutline);
export const InfoSolid = withIconProps('InfoSolid', RawInfoSolid);
export const Initiative = withIconProps('Initiative', RawInitiative);
export const InitiativeJira = withIconProps(
  'InitiativeJira',
  RawInitiativeJira
);
export const KeyResult = withIconProps('KeyResult', RawKeyResult);
export const Kudos = withIconProps('Kudos', RawKudos);
export const Learning = withIconProps('Learning', RawLearning);
export const Lightning = withIconProps('Lightning', RawLightning);
export const Link = withIconProps('Link', RawLink);
export const LinkOff = withIconProps('LinkOff', RawLinkOff);
export const LockClosed = withIconProps('LockClosed', RawLockClosed);
export const Login = withIconProps('Login', RawLogin);
export const Logout = withIconProps('Logout', RawLogout);
export const LongTermGoal = withIconProps('LongTermGoal', RawLongTermGoal);
export const Notes = withIconProps('Notes', RawNotes);
export const Notify = withIconProps('Notify', RawNotify);
export const OpenSidebar = withIconProps('OpenSidebar', RawOpenSidebar);
export const Organization = withIconProps('Organization', RawOrganization);
export const Overflow = withIconProps('Overflow', RawOverflow);
export const Pending = withIconProps('Pending', RawPending);
export const Plus = withIconProps('Plus', RawPlus);
export const Processing = withIconProps('Processing', RawProcessing);
export const ReportIssueOutline = withIconProps(
  'ReportIssueOutline',
  RawReportIssueOutline
);
export const ReportIssueSolid = withIconProps(
  'ReportIssueSolid',
  RawReportIssueSolid
);
export const Search = withIconProps('Search', RawSearch);
export const Send = withIconProps('Send', RawSend);
export const SendMe = withIconProps('SendMe', RawSendMe);
export const Settings = withIconProps('Settings', RawSettings);
export const SolidChevronDown = withIconProps(
  'SolidChevronDown',
  RawSolidChevronDown
);
export const SolidChevronLeft = withIconProps(
  'SolidChevronLeft',
  RawSolidChevronLeft
);
export const SolidChevronRight = withIconProps(
  'SolidChevronRight',
  RawSolidChevronRight
);
export const SolidChevronUp = withIconProps(
  'SolidChevronUp',
  RawSolidChevronUp
);
export const Sort = withIconProps('Sort', RawSort);
export const Star = withIconProps('Star', RawStar);
export const StrategicElement = withIconProps(
  'StrategicElement',
  RawStrategicElement
);
export const TalkingPoint = withIconProps('TalkingPoint', RawTalkingPoint);
export const Text = withIconProps('Text', RawText);
export const Updates = withIconProps('Updates', RawUpdates);
export const User = withIconProps('User', RawUser);
export const Value = withIconProps('Value', RawValue);
export const Video = withIconProps('Video', RawVideo);
export const ViewGraph = withIconProps('ViewGraph', RawViewGraph);
export const Wand = withIconProps('Wand', RawWand);
